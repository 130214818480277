<script setup>
import { useAuthStore } from '../stores/auth';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const queryParameterValue = ref('');

onMounted(() => {
    const paramName = 'code';
    if (route.query && paramName in route.query) {
        queryParameterValue.value = route.query[paramName];
        const authStore = useAuthStore();
        authStore.authGoogle(queryParameterValue.value);
    }
});
</script>
