import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import { toaster } from '@/utils/notification/notification';
import axios from '@/utils/axios';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useUserProfileStore = defineStore({
    id: 'userProfile',
    state: () => ({
        userProfile: []
    }),
    actions: {
        async setUserProfile(firstName, lastName, bio, phone, genderId) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await axios.post(`${baseUrl}/api/users/info`, { firstName, lastName, bio, phone, genderId });
                toaster.success('Your dates have been successfully changed!');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async getUserProfile() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/users/info`);
                this.userProfile = data;
                return data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async changePassword(password) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await axios.post(`${baseUrl}/api/users/changePassword`, { password });
                toaster.success('Your password has been successfully changed!');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        }
    },
    persist: true
});
