<template>
    <layout>
        <breadcrumb title="Terms & Condition" current="Terms & Condition" />

        <div class="terms-condition-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-2 col-lg-8">
                        <div class="condition-wrapper">
                            <div v-html="page"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import Layout from '@/components/layouts/Layout';
import { useAuthStore } from '@/stores/auth';

export default {
    name: 'TermsCondition',
    components: { Layout, Breadcrumb },
    async mounted() {
        const store = useAuthStore();
        const page = await store.getPages(2);
        this.page = page.value;
    },
    data() {
        return {
            page: null
        };
    }
};
</script>
