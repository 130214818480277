<template>
    <div class="nu-subscribe-area rn-section-gapTop"
         data-sal-delay="200"
         data-sal="slide-up"
         data-sal-duration="800">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="subscribe-wrapper_1 text-center">
                        <h3 class="title mb--10">Join our newsletter</h3>
                        <p class="subtitle">Weekly FREE UI resource stroight to your inbox</p>
                        <div class="subscribe-input-wrapper">
                            <div class="input-group">
                                <input type="email" class="form-control bg-color--2" placeholder="Your email" aria-label="Recipient's email">
                                <div class="input-group-append">
                                    <button class="btn btn-primary-alta btn-outline-secondary" type="button">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SubscriptionStyleOne'
    }
</script>