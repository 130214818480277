import { defineStore } from 'pinia';

export const useLoaderStore = defineStore({
    id: 'loader',
    state: () => ({
        isLoader: false
    }),
    actions: {
        async toggleLoader() {
            this.isLoader = !this.isLoader;
        }
    },
    persist: true
});
