/**
 * axios setup to use mock service
 */
import { useAuthStore } from '@/stores/auth';
import axios from 'axios';
import { toaster } from '@/utils/notification/notification';

const axiosServices = axios.create();

axiosServices.interceptors.request.use(
    (config) => {
        const { token } = useAuthStore();
        config.headers = config.headers ?? {};
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        const { logout } = useAuthStore();
        const data = error.response.data;
        if ([401, 403].includes(error.response.status)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            logout();
        }

        const e = (data && data.message) || error.statusText;
        toaster.error(e);
        return Promise.reject(e);
    }
);

export default axiosServices;
