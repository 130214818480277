<template>
    <div class="rn-new-items rn-section-gapTop">
        <div class="container">
            <div class="row mb--50 align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0 live-bidding-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">NFT</h3>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                    <div class="view-more-btn text-start text-sm-end">
                        <router-link class="btn-transparent" to="/product" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            VIEW ALL <i class="feather-arrow-right" />
                        </router-link>
                    </div>
                </div> -->
            </div>
            <div class="row g-5">
                <!-- data-sal="slide-up"
                :data-sal-delay="150 + index"
                data-sal-duration="800" -->
                <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12" v-for="(item, index) in newestItemList" :key="`newest-item-${index}`">
                    <product-card :product-date="item" product-style-class="no-overlay" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useItemsStore } from '@/stores/items';
import ProductCard from '@/components/product/ProductCard';

export default {
    name: 'ExploreNewestItem',
    components: { ProductCard },
    data() {
        return {
            // newestItemList: []
        };
    },
    computed: {
        newestItemList() {
            const itemsStore = useItemsStore();
            return itemsStore.items;
        }
    },
    async mounted() {
        const itemsStore = useItemsStore();
        await itemsStore.getItems();
    }
};
</script>
