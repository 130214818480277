<template>
    <loader
        v-if="isLoader"
        name="circular"
        loadingText="LOADING..."
        textColor="#ffffff"
        textSize="15"
        textWeight="800"
        object="#815aa5"
        color1="#ffffff"
        color2="##0d6efd"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        :disableScrolling="false"
    ></loader>
</template>

<script>
export default {
    props: {
        isLoader: {
            type: Boolean,
            default: false
        }
    }
};
</script>
