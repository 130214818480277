<template>
    <layout>
        <breadcrumb title="Activate" current="Activate" />

        <div class="forget-password-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="offset-4 col-lg-4">
                        <div class="form-wrapper-one">
                            <div class="logo-thumbnail logo-custom-css mb--50">
                                <router-link class="logo-light" to="/">
                                    <img v-if="url" :src="url" alt="nft-logo" />
                                </router-link>
                                <router-link class="logo-dark" to="/">
                                    <img v-if="url" :src="url" alt="nft-logo" />
                                </router-link>
                            </div>

                            <div class="mb-5">
                                <label for="exampleInputEmail1" class="form-label">Code</label>
                                <input
                                    type="email"
                                    id="exampleInputEmail1"
                                    placeholder="Enter your code"
                                    disabled
                                    v-model="queryParameterValue"
                                />
                            </div>
                            <!-- <div class="mb-5">
                                <input type="checkbox" class="rn-check-box-input" id="exampleCheck1" />
                                <label class="rn-check-box-label" for="exampleCheck1">
                                    I agree to the <router-link to="/privacy-policy">privacy policy</router-link>
                                </label>
                            </div> -->
                            <div class="mb-5">
                                <button class="btn btn-large btn-primary" @click.prevent="submitForm">Send</button>
                            </div>
                            <span class="mt--20 notice">Note: To confirm the activation, press the send button</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { useRoute } from 'vue-router';
import Layout from '@/components/layouts/Layout';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';

export default {
    name: 'Activate',
    components: { Breadcrumb, Layout },
    data() {
        return {
            queryParameterValue: '',
            url: ''
        };
    },
    async mounted() {
        const route = useRoute();
        const paramName = 'activationLink';
        if (route.query && paramName in route.query) {
            this.queryParameterValue = route.query[paramName];
        }

        const store = useAuthStore();
        const url = await store.getPages(12);
        this.url = url.value;
    },
    methods: {
        submitForm() {
            const authStore = useAuthStore();
            authStore.activateAccount(this.queryParameterValue);
        }
    }
};
</script>

<style scoped></style>
