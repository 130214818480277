<template>
    <layout>
        <breadcrumb title="Edit Profile" current="Edit Profile" />

        <div class="edit-profile-area rn-section-gapTop">
            <div class="container">
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-12 d-flex justify-content-between mb--30 align-items-center">
                        <h4 class="title-left">Edit Your Profile</h4>
                        <!-- <router-link to="/author/1" class="btn btn-primary ml--10"> <i class="feather-eye mr--5"></i> Preview </router-link> -->
                    </div>
                </div>
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <nav class="left-nav rbt-sticky-top-adjust-five">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button
                                    class="nav-link active"
                                    id="nav-home-tabs"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-homes"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-homes"
                                    aria-selected="false"
                                >
                                    <i class="feather-user" /> Personal Information
                                </button>
                                <button
                                    class="nav-link"
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                >
                                    <i class="feather-unlock" /> Change Password
                                </button>
                            </div>
                        </nav>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12 mt_sm--30">
                        <div class="tab-content tab-content-edit-wrapepr" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-homes" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="input-two-wrapper mb--15">
                                            <div class="first-name half-wid">
                                                <label for="contact-name" class="form-label">First Name</label>
                                                <input v-model="formData.firstName" id="contact-name" type="text" />
                                                <span v-if="v$.formData.firstName.$error" class="text-danger mt-2 d-inline-block">
                                                    {{ v$.formData.firstName.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="last-name half-wid">
                                                <label for="contact-name-last" class="form-label">Last Name</label>
                                                <input v-model="formData.lastName" id="contact-name-last" type="text" />
                                                <span v-if="v$.formData.lastName.$error" class="text-danger mt-2 d-inline-block">
                                                    {{ v$.formData.lastName.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="email-area">
                                            <label for="Email" class="form-label">Edit Your Email</label>
                                            <input v-model="formData.email" disabled id="Email" type="email" />
                                        </div>
                                    </div>
                                    <div class="edit-bio-area mt--30">
                                        <label for="description" class="form-label">Edit Your Bio</label>
                                        <textarea v-model="formData.bio" id="description"></textarea>
                                        <span v-if="v$.formData.bio.$error" class="text-danger mt-2 d-inline-block">
                                            {{ v$.formData.bio.$errors[0].$message }}
                                        </span>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label for="PhoneNumber" class="form-label mb--10">Phone Number</label>
                                            <input v-model="formData.phone" id="PhoneNumber" type="text" />
                                            <span v-if="v$.formData.phone.$error" class="text-danger mt-2 d-inline-block">
                                                {{ v$.formData.phone.$errors[0].$message }}
                                            </span>
                                        </div>
                                        <select v-model="formData.genderId" class="profile-edit-select">
                                            <option :value="null">Select Your Gender</option>
                                            <option :value="1">Male</option>
                                            <option :value="2">Female</option>
                                            <option :value="3">Third Gender</option>
                                        </select>
                                    </div>

                                    <div class="button-area save-btn-edit">
                                        <a href="#" class="btn btn-primary" @click.prevent="submitProfileForm">Save</a>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="nuron-information">
                                    <div class="condition">
                                        <h5 class="title">Create Your Password</h5>
                                        <p class="condition">
                                            Passwords are a critical part of information and network security. Passwords serve to protect
                                            user accounts but a poorly chosen password, if compromised, could put the entire network at
                                            risk.
                                        </p>
                                        <hr />
                                    </div>
                                    <div class="input-two-wrapper mt--15">
                                        <div class="old-password half-wid">
                                            <label for="NewPass" class="form-label">Create New Password</label>
                                            <input v-model="changePassword.password" id="NewPass" type="password" />
                                        </div>
                                        <div class="new-password half-wid">
                                            <label for="rePass" class="form-label">Confirm Password</label>
                                            <input v-model="changePassword.confirmPassword" id="rePass" type="password" />
                                            <span v-if="!equalPassword" class="text-danger mt-2 d-inline-block">
                                                Passwords do not match
                                            </span>
                                        </div>
                                    </div>
                                    <a href="#" class="btn btn-primary save-btn-edit" @click.prevent="submitChangePasswordForm">Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { useUserProfileStore } from '@/stores/userProfile';
import Layout from '@/components/layouts/Layout';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, maxLength } from '@vuelidate/validators';

export default {
    name: 'EditProfilePage',
    components: { Breadcrumb, Layout },
    data() {
        return {
            v$: useVuelidate(),
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                bio: '',
                phone: '',
                genderId: null
            },
            changePassword: {
                password: '',
                confirmPassword: ''
            }
        };
    },
    validations() {
        return {
            formData: {
                firstName: { required },
                lastName: { required },
                bio: { maxLength: maxLength(300) },
                phone: { required }
                // genderId: { required }
            }
        };
    },
    computed: {
        equalPassword() {
            return this.changePassword.password === this.changePassword.confirmPassword;
        }
    },
    async mounted() {
        const userProfileStore = useUserProfileStore();
        const data = await userProfileStore.getUserProfile();
        for (const key in this.formData) {
            this.formData[key] = data[key];
        }
    },
    methods: {
        submitProfileForm() {
            this.v$.$validate();
            if (!this.v$.$error) {
                const userProfileStore = useUserProfileStore();
                userProfileStore.setUserProfile(
                    this.formData.firstName,
                    this.formData.lastName,
                    this.formData.bio,
                    this.formData.phone,
                    this.formData.genderId
                );
            }
        },
        submitChangePasswordForm() {
            if (this.equalPassword) {
                const userProfileStore = useUserProfileStore();
                userProfileStore.changePassword(this.changePassword.password);
                this.changePassword.password = this.changePassword.confirmPassword = '';
            }
        }
    }
};
</script>
