<template>
    <layout>
        <breadcrumb title="Sign Up" current="Sign Up" />

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="offset-2 col-lg-4 col-md-6 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one registration-area">
                            <h4>Sign up</h4>
                            <form>
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" id="exampleInputEmail1" v-model.trim="formData.email" />
                                    <span v-if="v$.formData.email.$error" class="text-danger mt-2 d-inline-block">
                                        {{ v$.formData.email.$errors[0].$message }}
                                    </span>
                                </div>
                                <div class="mb-5">
                                    <label for="newPassword" class="form-label">Create Password</label>
                                    <input type="password" id="newPassword" v-model.trim="formData.password" />
                                    <span v-if="v$.formData.password.$error" class="text-danger mt-2 d-inline-block">
                                        {{ v$.formData.password.$errors[0].$message }}
                                    </span>
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input type="checkbox" class="rn-check-box-input" id="exampleCheck1" />
                                    <label class="rn-check-box-label" for="exampleCheck1">Allow to all tearms & condition</label>
                                </div>
                                <button type="submit" class="btn btn-primary mr--15" @click.prevent="submitForm">Sign Up</button>
                                <router-link to="/login" class="btn btn-primary-alta">Log In</router-link>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to sign up</h6>
                            <p>Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <a :href="`${baseName}/api/auth/google`">
                                <button class="another-login login-facebook">
                                    <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="" />
                                    <span>Log in with Google</span>
                                </button>
                            </a>
                            <!-- <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="" />
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="" />
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="" />
                                <span>Log in with linkedin</span>
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import Layout from '@/components/layouts/Layout';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';

export default {
    name: 'Signup',
    components: { Breadcrumb, Layout },
    data() {
        return {
            v$: useVuelidate(),
            baseName: process.env.VUE_APP_URL,
            formData: {
                email: '',
                password: ''
            }
        };
    },
    validations() {
        return {
            formData: {
                email: { required, email },
                password: { required, minLength: minLength(8) }
            }
        };
    },
    methods: {
        submitForm() {
            if (!this.v$.$validate()) return;
            this.v$.$validate();
            if (!this.v$.$error) {
                const authStore = useAuthStore();
                authStore.register(this.formData.email, this.formData.password);
            }
        }
    }
};
</script>
