<template>
    <layout>
        <breadcrumb title="Why We" current="Why We" />

        <div class="terms-condition-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-2 col-lg-8">
                        <div class="condition-wrapper">
                            <div v-html="page"></div>
                            <!-- <p>
                                Welcome to our unique service, where we inspire our customers to read and offer the chance to win valuable
                                prizes. We firmly believe in the power of knowledge and its ability to transform lives. That's why we
                                provide limited edition knowledge brochures at nominal prices, allowing our customers to expand their minds
                                and boost their knowledge.
                            </p>
                            <p>
                                But that's not all - we're also giving away free prizes! With every purchase, our customers receive a free
                                ticket for a chance to win one of our luxurious and coveted prizes. From designer goods to
                                once-in-a-lifetime experiences, our prizes are sure to satisfy even the most discerning tastes.
                            </p>
                            <p>
                                So read to win! With our limited edition knowledge brochures, not only will you gain access to life-changing
                                information, but you'll also have the opportunity to win your dream prize and own that precious commodity
                                you've always desired.
                            </p>
                            <p>
                                And the best part? You don't have to pay a penny extra for your chance to win. Simply purchase one of our
                                brochures and you'll be automatically entered into our prize draw.
                            </p>
                            <p>
                                So why wait? Don't miss out on this incredible opportunity to start your journey towards knowledge and
                                wealth while also having the chance to win amazing prizes. Join us today and unlock the power of your mind,
                                all while potentially winning your dream prize!
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import Layout from '@/components/layouts/Layout';
import { useAuthStore } from '@/stores/auth';

export default {
    name: 'WhyWe',
    components: { Layout, Breadcrumb },
    async mounted() {
        const store = useAuthStore();
        const page = await store.getPages(7);
        this.page = page.value;
    },
    data() {
        return {
            page: null
        };
    }
};
</script>
