import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'animate.css';
import './assets/css/feature.css';
import './assets/scss/style.scss';
import 'bootstrap';
import { createPinia } from 'pinia';
import dayjs from 'dayjs';

// Loader
import loader from 'vue3-ui-preloader';
import 'vue3-ui-preloader/dist/loader.css';

// pinia-plugin-persistedstate
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(router);
app.use(pinia);
app.component('loader', loader);
app.config.globalProperties.$dayjs = dayjs;
app.mount('#app');
