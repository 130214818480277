<template>
    <layout>
        <breadcrumb title="NFT Details" current="NFT Details" />

        <!-- Start NFT Details area -->
        <div class="product-details-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <!-- Product image area Start -->
                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <div class="product-tab-wrapper rbt-sticky-top-adjust">
                            <div class="pd-tab-inner">
                                <div
                                    class="nav rn-pd-nav rn-pd-rt-content nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <button
                                        :class="[`nav-link`, { active: index === activeTabIndex }]"
                                        :id="`v-pills-${index}-tab`"
                                        data-bs-toggle="pill"
                                        :data-bs-target="`#v-pills-home-${index}`"
                                        type="button"
                                        role="tab"
                                        :aria-controls="`v-pills-home-${index}`"
                                        :aria-selected="index === activeTabIndex ? 'true' : 'false'"
                                        v-for="(thumbnailSm, index) in product.imageUrlArr"
                                        :key="`small-thumbnail-${index}`"
                                    >
                                        <span class="rn-pd-sm-thumbnail">
                                            <img width="164" height="164" :src="thumbnailSm.src" alt="Nft_Profile" />
                                        </span>
                                    </button>
                                </div>
                                <div class="tab-content rn-pd-content" id="v-pills-tabContent">
                                    <div
                                        :class="[`tab-pane fade`, { 'show active': index === activeTabIndex }]"
                                        :id="`v-pills-home-${index}`"
                                        role="tabpanel"
                                        :aria-labelledby="`v-pills-${index}-tab`"
                                        v-for="(thumbnailLg, index) in product.imageUrlArr"
                                        :key="`large-thumbnail-${index}`"
                                    >
                                        <div class="rn-pd-thumbnail">
                                            <img :src="thumbnailLg.src" alt="Nft_Profile" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product image area end -->

                    <div class="col-lg-5 col-md-12 col-sm-12 mt_md--50 mt_sm--60">
                        <div class="rn-pd-content-area">
                            <div class="pd-title-area">
                                <h4 class="title">{{ product.name }}</h4>
                                <!-- <div class="pd-react-area">
                                    <div class="heart-count">
                                        <i class="feather-heart" />
                                        <span>{{ product.reacted }}</span>
                                    </div>
                                    <div class="count">
                                        <div class="share-btn share-btn-activation dropdown">
                                            <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="feather-more-horizontal" />
                                            </button>
                                            <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                                                <button
                                                    type="button"
                                                    class="btn-setting-text share-text"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#shareModal"
                                                >
                                                    Share
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn-setting-text report-text"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#reportModal"
                                                >
                                                    Report
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <span v-if="product.isFinished" class="bid"> Finished </span>
                            <span v-else class="bid"> Drawing Date: {{ product.dateStart }} </span>
                            <h6 class="title-name">Price: {{ product.price }} (MATIC)</h6>
                            <!-- <div class="catagory-collection">
                                <div class="catagory">
                                    <span> Catagory <span class="color-body">10% royalties</span> </span>
                                    <div class="top-seller-inner-one">
                                        <div class="top-seller-wrapper">
                                            <div class="thumbnail">
                                                <router-link to="/author/1">
                                                    <img :src="require(`@/assets/images/client/client-1.png`)" alt="Nft_Profile" />
                                                </router-link>
                                            </div>
                                            <div class="top-seller-content">
                                                <router-link to="/author/1">
                                                    <h6 class="name">Brodband</h6>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collection">
                                    <span>Collections</span>
                                    <div class="top-seller-inner-one">
                                        <div class="top-seller-wrapper">
                                            <div class="thumbnail">
                                                <router-link to="/author/2">
                                                    <img :src="require(`@/assets/images/client/client-2.png`)" alt="Nft_Profile" />
                                                </router-link>
                                            </div>
                                            <div class="top-seller-content">
                                                <router-link to="/author/2">
                                                    <h6 class="name">Brodband</h6>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div v-if="product.qty > 0 && !product.isFinished">
                                <h6 v-if="product.qty > 0">Qty: {{ product.qty }}</h6>
                                <crossmint-pay-button
                                    :collectionId="product.mintCollectionId"
                                    :projectId="product.projectId"
                                    mintConfig='{"quantity":"1","type":"managed-erc-721"}'
                                    :mintTo="product.mintTo"
                                    paymentMethod="fiat"
                                />
                            </div>
                            <h6 v-else>Sold Out</h6>
                            <!-- <router-link class="btn btn-primary-alta" to="#">Unlockable content included</router-link> -->
                            <div class="rn-bid-details">
                                <div class="tab-wrapper-one">
                                    <nav class="tab-button-one">
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button
                                                class="nav-link active"
                                                id="nav-profile-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-profile"
                                                aria-selected="true"
                                            >
                                                Details
                                            </button>
                                            <button
                                                class="nav-link"
                                                id="nav-contact-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-contact"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-contact"
                                                aria-selected="false"
                                            >
                                                History
                                            </button>
                                        </div>
                                    </nav>
                                    <div class="tab-content rn-bid-content" id="nav-tabContent">
                                        <div
                                            class="tab-pane fade show active"
                                            id="nav-profile"
                                            role="tabpanel"
                                            aria-labelledby="nav-profile-tab"
                                        >
                                            <div class="rn-pd-bd-wrapper">
                                                <div class="top-seller-inner-one">
                                                    <h6 class="name-title">
                                                        {{ product.description }}
                                                        <!-- If you buy this NFT you got a chance to win some NFT for free -->
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                            <div v-if="product.winnerTab" class="top-seller-inner-one">
                                                <span
                                                    ><router-link to="#">{{ product.winnerTab.userName }}</router-link
                                                    ><br />{{ product.winnerTab.ticket }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End NFT Details area -->

        <share-modal />

        <report-modal />

        <placebid-modal />
    </layout>
</template>

<script>
import { useItemsStore } from '@/stores/items';
import { useRoute } from 'vue-router';
import Layout from '@/components/layouts/Layout';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import ProductCard from '@/components/product/ProductCard';
import ShareModal from '@/components/modal/ShareModal';
import ReportModal from '@/components/modal/ReportModal';
import Countdown from '@/components/product/Countdown';
import PlacebidModal from '@/components/modal/PlacebidModal';
import ProductMixin from '@/mixins/ProductMixin';

export default {
    name: 'ProductDetails',
    components: { PlacebidModal, Countdown, ReportModal, ShareModal, ProductCard, Breadcrumb, Layout },
    mixins: [ProductMixin],
    data() {
        return {
            id: this.$route.params.id,
            product: {},
            activeTabIndex: 0
        };
    },
    methods: {
        getProduct(productId) {
            this.product = this.products.find((item) => item.id == productId);
        }
    },
    computed: {
        product() {
            const itemsStore = useItemsStore();
            return itemsStore.item;
        }
    },
    async created() {
        const route = useRoute();
        const itemsStore = useItemsStore();
        await itemsStore.getItem(route.params.id);
        this.getProduct(this.id);
    },
    watch: {
        '$route.params.id': function (val) {
            this.id = val;
            this.getProduct(this.id);
        }
    }
};
</script>
