<template>
    <div class="category-area pt--70">
        <div class="container">
            <div class="row g-5">
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="200" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-image"></i>
                        <span class="category-label">Art</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="300" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-music"></i>
                        <span class="category-label">Music</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="400" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-map"></i>
                        <span class="category-label">Digital Worlds</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="500" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-search"></i>
                        <span class="category-label">Domane Name</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="600" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-grid"></i>
                        <span class="category-label">Collectibles</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="700" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="#">
                        <i class="feather-activity"></i>
                        <span class="category-label">Trending Cards</span>
                    </a>
                </div>
                <!-- end single category -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CategoryStyleOne'
    }
</script>