import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import HomePageOne from '../views/home/HomePageOne';
import HomePageTwo from '../views/home/HomePageTwo';
import HomePageThree from '../views/home/HomePageThree';
import HomePageFour from '../views/home/HomePageFour';
import HomePageFive from '../views/home/HomePageFive';
import HomePageSix from '../views/home/HomePageSix';
import HomePageSeven from '../views/home/HomePageSeven';
import HomePageEight from '../views/home/HomePageEight';
import HomePageNine from '../views/home/HomePageNine';
import HomePageTen from '../views/home/HomePageTen';
import HomePageEleven from '../views/home/HomePageEleven';
import HomePageTwelve from '../views/home/HomePageTwelve';
import CreateProduct from '../views/CreateProduct';
import ProductDetails from '../views/ProductDetails';
import AuthGoogle from '../views/AuthGoogle';
import Connect from '../views/Connect';
import Collection from '../views/Collection';
import Creators from '../views/Creators';
import AuthorDetails from '../views/AuthorDetails';
import EditProfilePage from '../views/EditProfilePage';
import About from '../views/About';
import AboutUs from '../views/AboutUs';
import WhyWe from '../views/WhyWe';
import Winners from '../views/Winners';
import Orders from '../views/Orders';
import Wallet from '../views/Wallet';
import Contact from '../views/Contact';
import BlogDetails from '../views/blog/BlogDetails';
import BlogCategoryPosts from '../views/blog/BlogCategoryPosts';
import BlogTagPosts from '../views/blog/BlogTagPosts';
import BlogSingleColumn from '../views/blog/BlogSingleColumn';
import BlogTwoColumn from '../views/blog/BlogTwoColumn';
import BlogThreeColumn from '../views/blog/BlogThreeColumn';
import BlogFourColumn from '../views/blog/BlogFourColumn';
import ExploreOne from '../views/ExploreOne';
import ExploreTwo from '../views/ExploreTwo';
import ExploreThree from '../views/ExploreThree';
import ExploreFour from '../views/ExploreFour';
import ExploreFive from '../views/ExploreFive';
import ExploreSix from '../views/ExploreSix';
import ExploreSeven from '../views/ExploreSeven';
import ExploreEight from '../views/ExploreEight';
import ExploreNine from '../views/ExploreNine';
import ExploreTen from '../views/ExploreTen';
import ExploreEleven from '../views/ExploreEleven';
import ExploreTwelve from '../views/ExploreTwelve';
import ExploreThirteen from '../views/ExploreThirteen';
import ExploreFourteen from '../views/ExploreFourteen';
import UploadVariants from '../views/UploadVariants';
import Activity from '../views/Activity';
import UpcomingProjects from '../views/UpcomingProjects';
import Login from '../views/Login';
import Signup from '../views/Signup';
import ForgetPassword from '../views/ForgetPassword';
import Activate from '../views/Activate';
import PrivacyPolicy from '../views/PrivacyPolicy';
import Products from '../views/Products';
import Ranking from '../views/Ranking';
import NotFound from '../views/NotFound';
import Support from '../views/Support';
import TermsCondition from '../views/TermsCondition';
import ComingSoon from '../views/ComingSoon';
import Maintenance from '../views/Maintenance';
import { useFavicon } from '@vueuse/core';

const baseName = process.env.VUE_APP_NAME;

const routes = [
    {
        path: '/',
        name: 'HomePageOne',
        component: HomePageOne,
        meta: {
            title: `${baseName} - NFT Marketplace Template`
        }
    },
    {
        path: '/index-02',
        name: 'HomePageTwo',
        component: HomePageTwo,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-03',
        name: 'HomePageThree',
        component: HomePageThree,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-04',
        name: 'HomePageFour',
        component: HomePageFour,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-05',
        name: 'HomePageFive',
        component: HomePageFive,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-06',
        name: 'HomePageSix',
        component: HomePageSix,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-07',
        name: 'HomePageSeven',
        component: HomePageSeven,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-08',
        name: 'HomePageEight',
        component: HomePageEight,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-09',
        name: 'HomePageNine',
        component: HomePageNine,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-10',
        name: 'HomePageTen',
        component: HomePageTen,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-11',
        name: 'HomePageEleven',
        component: HomePageEleven,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/index-12',
        name: 'HomePageTwelve',
        component: HomePageTwelve,
        meta: {
            title: `${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/create',
        name: 'CreateProduct',
        component: CreateProduct,
        meta: {
            title: `Create || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/product/:id',
        name: 'ProductDetails',
        component: ProductDetails,
        meta: {
            title: `Product Details || ${baseName} - NFT Marketplace`,
            requiresAuth: true
        }
    },
    {
        path: '/auth/google',
        name: 'AuthGoogle',
        component: AuthGoogle,
        meta: {
            title: `Auth Google || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Connect,
        meta: {
            title: `Connect || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/collection',
        name: 'Collection',
        component: Collection,
        meta: {
            title: `Author || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/creator',
        name: 'Creators',
        component: Creators,
        meta: {
            title: `Collection || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/author/:id',
        name: 'AuthorDetails',
        component: AuthorDetails,
        meta: {
            title: `Author Details || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/edit-profile',
        name: 'EditProfilePage',
        component: EditProfilePage,
        meta: {
            title: `Edit Profile || ${baseName} - NFT Marketplace`,
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: `About || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/about-us',
        name: 'About Us',
        component: AboutUs,
        meta: {
            title: `About Us || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/why-we',
        name: 'Why We',
        component: WhyWe,
        meta: {
            title: `Why We || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/winners',
        name: 'Winners',
        component: Winners,
        meta: {
            title: `Winners || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta: {
            title: `Orders || ${baseName} - NFT Marketplace`,
            requiresAuth: true
        }
    },
    {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet,
        meta: {
            title: `Wallet || ${baseName} - NFT Marketplace`,
            requiresAuth: true
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: `Contact || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/category/:slug',
        name: 'BlogCategoryPosts',
        component: BlogCategoryPosts,
        meta: {
            title: `Blog Category List || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/tag/:slug',
        name: 'BlogTagPosts',
        component: BlogTagPosts,
        meta: {
            title: `Blog Tag List || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/blog-details/:id',
        name: 'BlogDetails',
        component: BlogDetails,
        meta: {
            title: `Blog Details || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/blog-single-column',
        name: 'BlogSingleColumn',
        component: BlogSingleColumn,
        meta: {
            title: `Blog Single Column || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/blog-col-two',
        name: 'BlogTwoColumn',
        component: BlogTwoColumn,
        meta: {
            title: `Blog Two Column || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/blog-col-three',
        name: 'BlogThreeColumn',
        component: BlogThreeColumn,
        meta: {
            title: `Blog Three Column || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/blog',
        name: 'BlogFourColumn',
        component: BlogFourColumn,
        meta: {
            title: `Blog Four Column || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-01',
        name: 'ExploreOne',
        component: ExploreOne,
        meta: {
            title: `Explore Filter || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-02',
        name: 'ExploreTwo',
        component: ExploreTwo,
        meta: {
            title: `Explore Isotop || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-03',
        name: 'ExploreThree',
        component: ExploreThree,
        meta: {
            title: `Explore Carousel || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-04',
        name: 'ExploreFour',
        component: ExploreFour,
        meta: {
            title: `Explore Simple || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-05',
        name: 'ExploreFive',
        component: ExploreFive,
        meta: {
            title: `Explore With Place Bid || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-06',
        name: 'ExploreSix',
        component: ExploreSix,
        meta: {
            title: `Place Bid With Filter || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-07',
        name: 'ExploreSeven',
        component: ExploreSeven,
        meta: {
            title: `Place Bid With Carousel || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-08',
        name: 'ExploreEight',
        component: ExploreEight,
        meta: {
            title: `Explore Carousel || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-09',
        name: 'ExploreNine',
        component: ExploreNine,
        meta: {
            title: `Explore List Style || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-10',
        name: 'ExploreTen',
        component: ExploreTen,
        meta: {
            title: `Explore List Column Two || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-11',
        name: 'ExploreEleven',
        component: ExploreEleven,
        meta: {
            title: `Live Explore || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-12',
        name: 'ExploreTwelve',
        component: ExploreTwelve,
        meta: {
            title: `Live Explore Carousel || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-13',
        name: 'ExploreThirteen',
        component: ExploreThirteen,
        meta: {
            title: `Live With Place Bid || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/explore-14',
        name: 'ExploreFourteen',
        component: ExploreFourteen,
        meta: {
            title: `Explore Filter Left || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/upload-variants',
        name: 'UploadVariants',
        component: UploadVariants,
        meta: {
            title: `Upload Variants || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity,
        meta: {
            title: `Activity || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/upcoming-projects',
        name: 'UpcomingProjects',
        component: UpcomingProjects,
        meta: {
            title: `Upcoming Projects || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: `Login || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup,
        meta: {
            title: `Signup || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/forget',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            title: `Forget Password || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/activate',
        name: 'Activate',
        component: Activate,
        meta: {
            title: `Activate || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: `Privacy Policy || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/product',
        name: 'Products',
        component: Products,
        meta: {
            title: `Product || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/ranking',
        name: 'Ranking',
        component: Ranking,
        meta: {
            title: `Ranking || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: `404 || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: `404 || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            title: `Ranking || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/terms-condition',
        name: 'TermsCondition',
        component: TermsCondition,
        meta: {
            title: `Terms & Condition || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: ComingSoon,
        meta: {
            title: `Coming Soon || ${baseName} - NFT Marketplace`
        }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            title: `Maintenance || ${baseName} - NFT Marketplace`
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    const auth = useAuthStore();
    const page = auth.getPages(11);
    const value = page.value;
    const icon = useFavicon();
    icon.value = value;
    // redirect to login page if not logged in and trying to access a restricted page
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!auth.token) {
            auth.returnUrl = to.fullPath;
            return next('/');
        } else {
            next();
            window.scrollTo(0, 0);
        }
    } else {
        next();
        window.scrollTo(0, 0);
    }
});

export default router;
