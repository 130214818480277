<template>
    <layout>
        <breadcrumb title="Forget Password?" current="Forget Password?" />

        <div class="forget-password-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="offset-4 col-lg-4">
                        <div class="form-wrapper-one">
                            <div class="logo-thumbnail logo-custom-css mb--50">
                                <router-link class="logo-light" to="/">
                                    <img v-if="url" :src="url" alt="nft-logo" />
                                </router-link>
                                <router-link class="logo-dark" to="/">
                                    <img v-if="url" :src="url" alt="nft-logo" />
                                </router-link>
                            </div>

                            <div class="mb-5">
                                <label for="exampleInputEmail1" class="form-label">Email address</label>
                                <input type="email" id="exampleInputEmail1" placeholder="Enter your email" v-model.trim="formData.email" />
                                <span v-if="v$.formData.email.$error" class="text-danger mt-2 d-inline-block">
                                    {{ v$.formData.email.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="mb-5">
                                <input type="checkbox" class="rn-check-box-input" id="exampleCheck1" />
                                <label class="rn-check-box-label" for="exampleCheck1">
                                    I agree to the <router-link to="/privacy-policy">privacy policy</router-link>
                                </label>
                            </div>
                            <div class="mb-5">
                                <button class="btn btn-large btn-primary" @click.prevent="submitForm">Send</button>
                            </div>
                            <span class="mt--20 notice">Note: We will send a password to your email</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import Layout from '@/components/layouts/Layout';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
export default {
    name: 'ForgetPassword',
    components: { Breadcrumb, Layout },
    data() {
        return {
            v$: useVuelidate(),
            formData: {
                email: ''
            },
            url: ''
        };
    },
    validations() {
        return {
            formData: {
                email: { required, email }
            }
        };
    },
    async mounted() {
        const store = useAuthStore();
        const url = await store.getPages(12);
        this.url = url.value;
    },
    methods: {
        submitForm() {
            this.v$.$validate();
            if (!this.v$.$error) {
                const authStore = useAuthStore();
                authStore.resetPassword(this.formData.email);
            }
        }
    }
};
</script>

<style scoped></style>
