import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import { toaster } from '@/utils/notification/notification';
import axios from '@/utils/axios';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useOrdersStore = defineStore({
    id: 'orders',
    state: () => ({
        ordersList: []
    }),
    actions: {
        async getOrders() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/users/orders`);
                this.ordersList = data;
                return data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        }
    }
});
