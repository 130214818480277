<template>
    <div class="logo-thumbnail logo-custom-css">
        <router-link class="logo-light" to="/">
            <img v-if="url" :src="url" alt="nft-logo" />
        </router-link>
        <router-link class="logo-dark" to="/">
            <img v-if="url" :src="url" alt="nft-logo" />
        </router-link>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';

export default {
    name: 'Logo',
    async mounted() {
        const store = useAuthStore();
        const url = await store.getPages(12);
        this.url = url.value;
    },
    data() {
        return {
            url: ''
        };
    }
};
</script>
