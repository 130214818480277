import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import router from '@/router';
import { toaster } from '@/utils/notification/notification';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        token: null,
        user: null,
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { token } = await fetchWrapper.post(`${baseUrl}/api/auth/login`, { email, password });
                // store user details and jwt in local storage to keep user logged in between page refreshes
                this.token = token;
                // this.user = await this.getUserLayout();
                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async getUserLayout() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                return await fetchWrapper.get(`${baseUrl}/api/users/layout`);
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async register(email, password) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await fetchWrapper.post(`${baseUrl}/api/auth/registration`, { email, password });
                toaster.info('An activation letter has been sent to your email address 😊');
                router.push('/login');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async activateAccount(activationLink) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await fetchWrapper.get(`${baseUrl}/api/auth/activate?activationLink=${activationLink}`);
                toaster.success('Your account is activated, welcome to our family! 👨‍👩‍👦');
                router.push('/login');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async contactUs(name, email, subject, message) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await fetchWrapper.post(`${baseUrl}/api/feedbacks`, { name, email, subject, message });
                toaster.info('Thank you. Your message was successfully sent. 😊');
                router.push('/');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async resetPassword(email) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                await fetchWrapper.post(`${baseUrl}/api/auth/resetPassword`, { email });
                toaster.success('A new password has been sent to your email address 📨');
                router.push('/login');
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async authGoogle(code) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const user = await fetchWrapper.get(`${baseUrl}/api/auth/google/callback?code=${code}`);
                // store user details and jwt in local storage to keep user logged in between page refreshes
                this.token = user.token;
                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                console.log(error);
                router.push('/auth/login');
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async authFacebook(code) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const user = await fetchWrapper.get(`${baseUrl}/api/auth/facebook/callback?code=${code}`);
                // store user details and jwt in local storage to keep user logged in between page refreshes
                this.token = user.token;
                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                console.log(error);
                router.push('/auth/login');
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async getPages(typeId) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const data = await fetchWrapper.get(`${baseUrl}/api/glossaries/byTypeId?typeId=${typeId}`);
                return data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async logout() {
            this.token = null;
            this.pages = [];
            router.push('/');
        }
    },
    persist: true
});
