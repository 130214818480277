import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import axios from '@/utils/axios';
import router from '@/router';
import { toaster } from '@/utils/notification/notification';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useItemsStore = defineStore({
    id: 'items',
    state: () => ({
        item: null,
        items: []
    }),
    actions: {
        async getItems() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/collections/list/user`);
                this.items = data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        },
        async getItem(id) {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/collections/${id}`);
                this.item = data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        }
    },
    persist: true
});
