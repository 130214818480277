<template>
    <layout>
        <div class="rn-activity-area rn-section-gapTop">
            <div class="container">
                <div class="row mb--30">
                    <h3 class="title">Wallet</h3>
                    <h6>{{ walletId }}</h6>
                </div>
                <div class="row g-6 activity-direction">
                    <div class="col-lg-12 mb_dec--15">
                        <div class="single-activity-wrapper" v-for="(activity, index) in walletList" :key="`activity-${index}`">
                            <div class="inner">
                                <div class="read-content">
                                    <div class="thumbnail">
                                        <router-link to="#">
                                            <img :src="activity.imageUrl" width="108" alt="Nft_Profile" />
                                        </router-link>
                                    </div>
                                    <div class="content">
                                        <h6 class="title">{{ activity.name }}</h6>
                                        <p v-html="activity.description"></p>
                                        <b>You own {{ activity.txIdArr.length }} NFT with those txHash:</b>
                                        <div class="mt--5">
                                            <!-- <div v-for="item in activity.txIdArr" :key="item">
                                                <span style="color: var(--color-primary)">{{ item }}</span>
                                            </div> -->
                                            <!-- <router-link class="btn btn-primary-alta" to="#">Unlockable content included</router-link> -->
                                            <div v-for="item in activity.txIdArr" :key="item" class="mb--3">
                                                <button
                                                    v-if="activity.isWinner"
                                                    @click="copyText"
                                                    class="btn btn-primary-alta mb--5"
                                                    style="background: green"
                                                >
                                                    <span class="cuttedText" :ref="`text-${activity.txIdArr}`">{{ item }}</span>
                                                </button>
                                                <button v-else @click="copyText" class="btn btn-primary-alta mb--5">
                                                    <span class="cuttedText" :ref="`text-${activity.txIdArr}`">{{ item }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- <div class="time-maintane">
                                            <div class="time data">
                                                <i class="feather-clock me-1" />
                                                <span>{{ activity.time }}</span>
                                            </div>
                                            <div class="user-area data">
                                                <i class="feather-user me-1" />
                                                <router-link :to="`/author/1`">{{ activity.author }}</router-link>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <div class="icone-area">
                                    <i :class="activity.icon" />
                                </div> -->
                                <div class="icone-area">
                                    <router-link :to="`/product/${activity.id}`">
                                        <button type="button" class="btn btn-primary mr--30">
                                            {{ activity.isFinished ? 'View NFT' : 'Buy NFT' }}
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4">
                        <div class="filter-wrapper">
                            <div class="widge-wrapper rbt-sticky-top-adjust">
                                <div class="inner">
                                    <h3>Market filter</h3>
                                    <div class="sing-filter">
                                        <button>Purchases</button>
                                        <button>Sales</button>
                                        <button>Followers</button>
                                        <button>Following</button>
                                        <button>Reserved</button>
                                        <button>Live Auction</button>
                                    </div>
                                </div>
                                <div class="inner">
                                    <h3>Filter by users</h3>
                                    <div class="sing-filter">
                                        <button>Love</button>
                                        <button>Saved</button>
                                        <button>Support us</button>
                                        <button>Report</button>
                                        <button>Vedio</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { useWalletStore } from '@/stores/wallet';
import { toaster } from '@/utils/notification/notification';
import Layout from '@/components/layouts/Layout';

export default {
    name: 'Activity',
    components: { Layout },
    computed: {
        walletList() {
            const walletStore = useWalletStore();
            return walletStore.walletList;
        },
        walletId() {
            const walletStore = useWalletStore();
            return walletStore.walletId;
        }
    },
    async mounted() {
        const walletStore = useWalletStore();
        await walletStore.getWallet();
    },
    methods: {
        copyText(e) {
            navigator.clipboard.writeText(`${e.target.innerText}`);
            toaster.success(`Copied the text: ${e.target.innerText}`);
        }
    }
};
</script>

<style>
.cuttedText {
    display: block;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
