import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import axios from '@/utils/axios';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useWinnersStore = defineStore({
    id: 'winners',
    state: () => ({
        winners: []
    }),
    actions: {
        async getWinners() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/winners/list`);
                this.winners = data;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        }
    },
    persist: true
});
