import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import axios from '@/utils/axios';
import router from '@/router';
import { toaster } from '@/utils/notification/notification';

const baseUrl = `${process.env.VUE_APP_URL}`;

export const useWalletStore = defineStore({
    id: 'wallet',
    state: () => ({
        walletId: null,
        walletList: []
    }),
    actions: {
        async getWallet() {
            const loaderStore = useLoaderStore();
            loaderStore.isLoader = true;
            try {
                const { data } = await axios.get(`${baseUrl}/api/users/collections`);
                this.walletList = data.collectionArr;
                this.walletId = data.walletId;
            } catch (error) {
                console.log(error);
            } finally {
                loaderStore.isLoader = false;
            }
        }
    },
    persist: true
});
